import { defineStore } from "pinia";

export const useModalImageSlider = defineStore("modalImageSlider", () => {
  const modalIsOpen = ref(false);
  const initialSlide = ref(0);
  const sliderImages = ref([]);
  // Set sliderImages to array of objects:
  // [
  //   {
  //     imageFullSrc: "/img/example.png",
  //     alt: "alt text",
  //   },
  //   ...
  // ]

  const closeModal = () => {
    modalIsOpen.value = false;
  };

  const openModal = (index) => {
    initialSlide.value = index;
    modalIsOpen.value = true;
  };

  return {
    modalIsOpen,
    initialSlide,
    sliderImages,
    closeModal,
    openModal,
  };
});
