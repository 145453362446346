import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import {
  GET_BLOG_CATEGORIES,
  GET_BLOG_POSTS,
  GET_BLOG_POSTS_BY_CATEGORY,
  GET_BLOG_POSTS_BY_IDS,
  GET_SINGLE_BLOG_POST_BY_URI,
  GET_POPULAR_BLOG_POSTS,
} from "~/graphql/GetBlogPosts";

export const useBlogPosts = defineStore("blogPosts", () => {
  const route = useRoute();
  const PAGE_SIZE = 6;
  const singleBlogPostUri = ref("");
  const currentPage = ref(1);
  const currentCategoryPage = ref(1);
  const popularBlogPosts = ref([]);
  const categorySlug = computed(() => route.params.category);

  // Blog Posts
  const { result, loading, fetchMore, refetch } = useQuery(GET_BLOG_POSTS, {
    first: PAGE_SIZE,
    last: null,
    after: null,
    before: null,
  });

  const refreshBlogPosts = () => {
    refetch();
  };

  const posts = computed(
    () => result?.value?.blogPosts.edges.map((edge) => edge.node) ?? []
  );

  const hasNextPage = computed(
    () => result?.value?.blogPosts.pageInfo.hasNextPage ?? false
  );

  const hasPreviousPage = computed(
    () => result?.value?.blogPosts.pageInfo.hasPreviousPage ?? false
  );

  const loadMore = (direction = "next") => {
    let first, last, after, before;

    switch (direction) {
      case "previous":
        if (!hasPreviousPage) return;
        last = PAGE_SIZE;
        before = result.value?.blogPosts.pageInfo.startCursor;
        currentPage.value = Math.max(currentPage.value - 1, 1);
        break;
      case "next":
      default:
        if (!hasNextPage) return;
        first = PAGE_SIZE;
        after = result.value?.blogPosts.pageInfo.endCursor;
        currentPage.value = currentPage.value + 1;
        break;
    }

    fetchMore({
      variables: {
        first: first,
        last: last,
        after: after,
        before: before,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult ?? previousResult;
      },
    });
  };

  // Blog Post Categories

  const {
    result: postsByCategoryResult,
    loading: postsByCategoryLoading,
    fetchMore: postsByCategoryFetchMore,
    refetch: postsByCategoryRefetch,
  } = useQuery(GET_BLOG_POSTS_BY_CATEGORY, {
    categorySlug: categorySlug,
    first: PAGE_SIZE,
    last: null,
    after: null,
    before: null,
  });

  watch(categorySlug, () => {
    postsByCategoryRefetch();
  });

  const postsByCategory = computed(() => {
    return (
      postsByCategoryResult?.value?.blogCategories?.edges?.[0]?.node?.blogPosts?.edges?.map(
        (edge) => edge.node
      ) ?? []
    );
  });

  const hasNextCategoryPage = computed(
    () =>
      postsByCategoryResult?.value?.blogCategories?.edges?.[0]?.node?.blogPosts
        ?.pageInfo.hasNextPage ?? false
  );

  const hasPreviousCategoryPage = computed(
    () =>
      postsByCategoryResult?.value?.blogCategories?.edges?.[0]?.node?.blogPosts
        ?.pageInfo.hasPreviousPage ?? false
  );

  const loadMoreCategoryPages = (direction = "next") => {
    let first, last, after, before;

    switch (direction) {
      case "previous":
        if (!hasPreviousCategoryPage) return;
        last = PAGE_SIZE;
        before =
          postsByCategoryResult?.value?.blogCategories?.edges?.[0]?.node
            ?.blogPosts?.pageInfo.startCursor;
        currentCategoryPage.value = Math.max(currentCategoryPage.value - 1, 1);
        break;
      case "next":
      default:
        if (!hasNextCategoryPage) return;
        first = PAGE_SIZE;
        after =
          postsByCategoryResult?.value?.blogCategories?.edges?.[0]?.node
            ?.blogPosts?.pageInfo.endCursor;
        currentCategoryPage.value = currentCategoryPage.value + 1;
        break;
    }

    postsByCategoryFetchMore({
      variables: {
        first: first,
        last: last,
        after: after,
        before: before,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult ?? previousResult;
      },
    });
  };

  // Single Blog Post

  const { result: singleBlogPostResult, loading: singleBlogPostLoading } =
    useQuery(GET_SINGLE_BLOG_POST_BY_URI, {
      id: singleBlogPostUri,
    });
  const singleBlogPost = computed(() => singleBlogPostResult.value ?? []);

  // Other: Sidebar

  const { result: blogCategoriesResult } = useQuery(GET_BLOG_CATEGORIES);
  const blogCategories = computed(
    () => blogCategoriesResult.value?.blogCategories.nodes ?? []
  );

  const { result: popularBlogPostsResult, loading: popularBlogPostsLoading } =
    useQuery(GET_POPULAR_BLOG_POSTS);

  // ToDo: This could use a refactor
  // First we query to get the database IDs of the selected popular blog posts on the options page. Unfortunately when you return the posts as objects, it does not include all the information needed. (Could probably change this on the theme side by fetching and including the data where we add the hook in ./hooks/graphql.php)
  // Instead, we need to use those IDs fetch their information in a follow-up query.
  // These need to happen asynchronously as one query depends on the other, hence setting up the watcher.
  // The problem is this is slow. Probably need to revisit the php hook.
  watch(popularBlogPostsLoading, (newPopularBlogPostsLoading) => {
    if (!newPopularBlogPostsLoading) {
      const popularBlogPostIds = computed(() => {
        let parsedJSON = {};
        try {
          parsedJSON = JSON.parse(
            popularBlogPostsResult?.value?.popularBlogPosts
          );
        } catch (e) {
          return {};
        }
        return parsedJSON;
      });

      const {
        result: getBlogPostsByIdsResult,
        loading: getBlogPostsByIdsLoading,
      } = useQuery(GET_BLOG_POSTS_BY_IDS, {
        in: popularBlogPostIds.value.blogPosts,
      });

      popularBlogPosts.value = computed(
        () =>
          getBlogPostsByIdsResult.value?.blogPosts.edges.map(
            (edge) => edge.node
          ) ?? []
      );
    }
  });

  return {
    // Blog Posts
    currentPage,
    hasNextPage,
    hasPreviousPage,
    loadMore,
    loading,
    posts,
    refreshBlogPosts,

    // Blog Post Categories
    categorySlug,
    currentCategoryPage,
    hasNextCategoryPage,
    hasPreviousCategoryPage,
    loadMoreCategoryPages,
    postsByCategory,
    postsByCategoryLoading,

    // Single Blog Post
    singleBlogPost,
    singleBlogPostLoading,
    singleBlogPostUri,

    // Other: Sidebar
    blogCategories,
    popularBlogPosts,
  };
});
