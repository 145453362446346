import { defaultCenter, defaultZoom, overviewMapOffset } from "./mapOptions";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export const offsetCenter = (map, latlng, offsetx, offsety) => {
  const projection = map.getProjection();

  if (typeof projection === "undefined") {

    // Add an event listener to recursively call this function once the map projection has updated
    google.maps.event.addListener(map, 'projection_changed', function() {
      offsetCenter(map, latlng, offsetx, offsety);
    });

  } else {

      const scale = Math.pow(2, map.getZoom());
      offsetx = typeof offsetx !== "undefined" ? offsetx : 0;
      offsety = typeof offsety !== "undefined" ? offsety : 0;

      const worldCoordinateCenter = projection.fromLatLngToPoint(latlng);
      const pixelOffset = new google.maps.Point(
        offsetx / scale || 0,
        offsety / scale || 0
      );

      const worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
      );

      const newCenter = projection.fromPointToLatLng(worldCoordinateNewCenter);

      map.panTo(newCenter);
  }
};

export const fitBoundsToMarkers = (map, mapMarkers) => {
  const bounds = new google.maps.LatLngBounds();
  mapMarkers.forEach((marker) => {
    bounds.extend(marker.getPosition());
  });
  map.fitBounds(bounds, 200);
};

export const addMarkerHoverState = (marker, icons) => {
  marker.addListener("mouseover", (event) => {
    event.domEvent.target.src = icons.green.icon;
  });
  marker.addListener("mouseout", (event) => {
    event.domEvent.target.src = icons.purple.icon;
  });
};

export const getInfoWindow = (infowindowContent, nodeIndex) => {
  if (infowindowContent) {
    const infoWindow = new google.maps.InfoWindow({
      content: infowindowContent[nodeIndex],
    });
    return infoWindow;
  }
};

export const addInfoWindowListeners = (
  infoWindow,
  infoWindows,
  marker,
  mapMarkers,
  map,
  listItemId,
  offset = true,
  infoWindowOnMobile = false
) => {
  const listItemElement = document.getElementById(listItemId);
  const allListItems = document.querySelectorAll(
    ".tabs-component article.item"
  );
  const selectedClass = "selected-item";
  marker.addListener("click", () => {
    infoWindows.forEach((iw) => iw.close());
    allListItems.forEach((item) => item.classList.remove(selectedClass));
    if (process.client
      // && window.innerWidth >= overviewMapOffset.breakpoint
    ) {
      if (window.innerWidth >= overviewMapOffset.breakpoint || infoWindowOnMobile) {
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      }
      if (offset) {
        offsetCenter(
          map,
          marker.getPosition(),
          overviewMapOffset.x,
          overviewMapOffset.y
        );
      }
    }
    listItemElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    listItemElement?.classList.add(selectedClass);
  });
  map.addListener("click", () => {
    infoWindows.forEach((iw) => iw.close());
    allListItems.forEach((item) => item.classList.remove(selectedClass));
  });
  infoWindow.addListener("closeclick", () => {
    fitBoundsToMarkers(map, mapMarkers);
    if (offset && process.client && window.innerWidth >= overviewMapOffset.breakpoint) {
      offsetCenter(
        map,
        map.getCenter(),
        overviewMapOffset.x,
        overviewMapOffset.y
      );
    }
    document
      .getElementById("overview-map")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
    allListItems.forEach((item) => item.classList.remove(selectedClass));
  });
};

export const setMarker = (node, icons, map, markerCluster = null) => {
  let marker = new google.maps.Marker({
    position: { lat: node.latitude, lng: node.longitude },
    title: node.title,
    icon: icons.purple.icon,
  });
  if (markerCluster instanceof MarkerClusterer) {
    markerCluster.addMarker(marker);
  } else {
    marker.setMap(map);
  }
  return marker;
};

export const clusterRenderer = {
  render: function ({ count, position }, stats) {
    const color = "#993366";
    // create svg url with fill color
    const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".99" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);
    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      title: `${count} items (click to zoom)`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  },
};
