export const getInfowindowContent = (mapInfo) => {
  const styles = `
    <style>
      /* Google Maps Custom Styles */
      .gm-style .gm-style-iw[role="dialog"] {
        padding: 0;
        border-radius: 0;
      }

      .gm-style .gm-style-iw[role="dialog"] > div {
        overflow: hidden !important;
      }

      .gm-style .gm-style-iw[role="dialog"] > button > span {
        display: none !important;
      }

      .gm-style .gm-style-iw[role="dialog"] > button {
        background-color: #111827 !important;
        border-radius: 50%;
        height: 2rem !important;
        width: 2rem !important;
        top: 0 !important;
        right: 0 !important;
      }

      .gm-style .gm-style-iw[role="dialog"] > button::before {
        content: '';
        background-image: url('/img/cancel-circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        width: 100%;
        height: 100%;
        display: block;
      }

      .gm-style .gm-style-iw-tc::after {
        background-color: #559cbe !important;
      }

      /* Infowindow Content Styles */
      .infowindow-content.infowindow-model-homes,
      .infowindow-content.infowindow-neighborhoods,
      .infowindow-content.infowindow-qmi-homes {
        width: auto;
        max-width: 283px;
        color: #1f2937;
      }

      .infowindow-content img {
        width: 100%;
        object-fit: cover;
        margin-bottom: 1rem;
        display: block;
        aspect-ratio: 2/1;
      }

      .infowindow-content .title {
        font-family: "Archer", serif;
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0.75rem;
        padding: 0 0.25rem;
      }

      .infowindow-content.infowindow-qmi-homes .title {
        margin-bottom: 0;
      }

      .infowindow-content .plan {
        font-family: "Ubuntu", sans-serif;
        font-size: 0.875rem;
        font-weight: 500;
        color: #a09f42;
        text-align: center;
        text-transform: uppercase;
        padding: 0 0.25rem;
        margin-bottom: 0.75rem;
      }

      .infowindow-content .details {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 0.75rem;
        padding: 0 0.25rem;
      }

      .infowindow-content .details div span {
        display: block;
        text-align: center;
        font-family: "Arial", sans-serif;
      }

      .infowindow-content .details div span:first-child {
        font-size: 1.375rem;
      }

      .infowindow-content .details div span:last-child {
        font-size: 0.75rem;
        font-weight: bold;
      }

      .infowindow-content .directions-link,
      .infowindow-content .subtitle {
        display: none;
      }

      .infowindow-content .explore-button {
        background-color: #559cbe;
        display: block;
        text-align: center;
        color: white;
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        padding: 11px 0.25rem;
      }

      .infowindow-content .explore-button::after {
        content: '\u00BB';
        margin-left: 0.25rem;
      }

      /* Responsive Styles */
      @media (min-width: 640px) {
        .infowindow-content.infowindow-model-homes,
        .infowindow-content.infowindow-neighborhoods {
          width: 283px;
        }

        .infowindow-content.infowindow-qmi-homes {
          width: 325px;
        }

        .infowindow-content img {
          height: 184px;
        }

        .infowindow-content .title {
          font-size: 1.5rem;
        }

        .infowindow-content .directions-link {
          display: block;
          color: #387592;
          font-family: "Ubuntu", sans-serif;
          font-size: 1rem;
          text-decoration: underline;
          text-align: center;
          display: flex;
          justify-content: center;
          gap: 0.25rem;
          align-items: center;
          font-weight: 500;
          margin-bottom: 1rem;
          padding: 0 0.25rem;
        }

        .infowindow-content .directions-link::before {
          content: '';
          background-color: #387592;
          background-image: url('/img/arrow-up-right.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 8px 8px;
          width: 1rem;
          height: 1rem;
          display: block;
          border-radius: 50%;
          clip-path: polygon(
            45% 2%,
            50% 1%,
            55% 2%,
            98% 45%,
            99% 50%,
            98% 55%,
            55% 98%,
            50% 99%,
            45% 98%,
            2% 55%,
            1% 50%,
            2% 45%
          );
        }

        .infowindow-content .subtitle {
          display: block;
          font-size: 1rem;
          color: #6b7280;
          font-family: "Arial", sans-serif;
          text-align: center;
          margin-bottom: 1rem;
          padding: 0 0.25rem;
        }

        .infowindow-content .explore-button {
          font-size: 1.125rem;
        }
      }
    </style>
  `;


  switch (mapInfo.value.mapView) {
    case "model-homes":
      return (
        mapInfo.value.nodes.map((node) => {
          const beds = (node.beds.min && node.beds.max) ? node.beds.min+' - '+node.beds.max : node.beds.min;
          const sqft = node?.sqft?.toLocaleString('en-US');
          return styles + `
            <div class="infowindow-content infowindow-model-homes">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <div class="details">
                <div>
                  <span>${beds}</span>
                  <span>BEDS</span>
                </div>
                <div>
                  <span>${sqft}</span>
                  <span>SQ. FT.</span>
                </div>
              </div>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );

    case "neighborhoods":
      return (
        mapInfo.value.nodes.map((node) => {
          return styles + `
            <div class="infowindow-content infowindow-neighborhoods">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <p class="subtitle">${node.subtitle}</p>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );

    case "quick-move-in":
      return (
        mapInfo.value.nodes.map((node) => {
          const beds = (node.beds.min && node.beds.max) ? node.beds.min+' - '+node.beds.max : node.beds.min;
          const baths = (node.baths.min && node.baths.max) ? node.baths.min+' - '+node.baths.max : node.baths.min;
          const sqft = node?.sqft?.toLocaleString('en-US');
          return styles + `
            <div class="infowindow-content infowindow-qmi-homes">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <h3 class="plan">${node.plan}</h3>
              <div class="details">
                <div>
                  <span>${beds}</span>
                  <span>BEDS</span>
                </div>
                <div>
                  <span>${baths}</span>
                  <span>BATH</span>
                </div>
                <div>
                  <span>${sqft}</span>
                  <span>SQ. FT.</span>
                </div>
              </div>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );
  }
};

