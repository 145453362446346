import gql from "graphql-tag";
import { BLOG_POST_FRAGMENT } from "./fragments.js";

export const GET_BLOG_POSTS = gql`
  ${BLOG_POST_FRAGMENT}
  query GetBlogPosts($first: Int, $last: Int, $after: String, $before: String ) {
    blogPosts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {orderby: {field: DATE, order: DESC}}
    ) {
      edges {
        node {
          ...BlogPostFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_BLOG_CATEGORIES = gql`
  query GetBlogCategories {
    blogCategories(where: {orderby: COUNT, order: DESC}) {
      nodes {
        count
        name
        slug
        uri
      }
    }
  }
`;

export const GET_POPULAR_BLOG_POSTS = gql`
  query GetPopularBlogPosts {
    popularBlogPosts
  }
`;

export const GET_BLOG_POSTS_BY_IDS = gql`
  ${BLOG_POST_FRAGMENT}
  query GetBlogPostsByIds($in: [ID]) {
    blogPosts(where: {in: $in}) {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`;

export const GET_BLOG_POSTS_BY_CATEGORY = gql`
  ${BLOG_POST_FRAGMENT}
  query GetBlogPostsByCategory($categorySlug: [String], $first: Int, $last: Int, $before: String, $after: String) {
    blogCategories(where: {slug: $categorySlug}) {
      edges {
        node {
          blogPosts(where: {orderby: {field: DATE, order: DESC}}, first: $first, last: $last, before: $before, after: $after) {
            edges {
              node {
                ...BlogPostFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_BLOG_POST_BY_URI = gql`
  ${BLOG_POST_FRAGMENT}
  query GetSingleBlogPostByUri($id: ID!) {
    blogPost(id: $id, idType: URI) {
      ...BlogPostFragment
    }
  }
`;

