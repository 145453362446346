import { defineStore } from "pinia";

export const useModalForm = defineStore("modalForm", () => {
  const modalIsOpen = ref(false);
  // const formId = ref(0);
  const currentStep = ref(1);
  const hideForm = ref(false);
  const formSubmitting = ref(false);
  const formResponseMessage = ref("");
  const formResponseMessageType = ref("");

  const $reset = () => {
    modalIsOpen.value = false;
    // formId.value = 0;
    currentStep.value = 1;
    hideForm.value = false;
    formSubmitting.value = false;
    formResponseMessage.value = "";
    formResponseMessageType.value = "";
  };

  const closeModal = () => {
    modalIsOpen.value = false;
    $reset();
  };

  const openModal = () => {
    modalIsOpen.value = true;
  };

  const nextStep = () => {
    currentStep.value++;
  };

  const prevStep = () => {
    currentStep.value--;
  };

  const focusTarget = (targetId) => {
    const target = document.getElementById(targetId);
    target.focus();
  };

  // Trivial Example
  // const submitForm = () => {
  //   // Your form submission logic here
  //   console.log("Form submitted");
  // };

  // const submitForm = async (formId, formData) => {
  //   hideForm.value = true;
  //   formSubmitting.value = true;
  //   formResponseMessage.value = "";
  //   formResponseMessageType.value = "";

  //   try {
  //     const response = await fetch(
  //       "https://staging-livebouldercreek.kinsta.cloud/wp-json/gf/v2/forms/" +
  //         formId +
  //         "/submissions",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("An error occurred during form submission");
  //     }

  //     const data = await response.json();

  //     // Handle successful form submission
  //     console.log(data);
  //     formResponseMessageType.value = "success";
  //     formResponseMessage.value = data.confirmation_message;
  //   } catch (error) {
  //     // Handle error during form submission
  //     console.error(error);
  //     formResponseMessageType.value = "error";
  //     formResponseMessage.value = "Sorry, an error occurred during form submission. Please contact us at <span style='white-space:nowrap;'>303-309-0362</span> if this issue persists.";
  //   } finally {
  //     formSubmitting.value = false;
  //   }
  // };

  // Track active modals
  const activeModalIds = ref(new Set());

  const isModalActive = (modalId) => {
    return activeModalIds.value.has(modalId);
  };

  const registerModal = (modalId) => {
    activeModalIds.value.add(modalId);
  };

  const unregisterModal = (modalId) => {
    activeModalIds.value.delete(modalId);
  };

  const openModalWithCheck = (modalId) => {
    if (!isModalActive(modalId)) {
      openModal();
      registerModal(modalId);
    }
  };

  const closeModalWithUnregister = (modalId) => {
    closeModal();
    if (modalId) unregisterModal(modalId);
  };

  return {
    $reset,
    closeModal,
    currentStep,
    focusTarget,
    // formId,
    formResponseMessage,
    formResponseMessageType,
    formSubmitting,
    hideForm,
    modalIsOpen,
    nextStep,
    openModal,
    prevStep,
    // submitForm,
    isModalActive,
    openModalWithCheck,
    closeModalWithUnregister,
  };
});
