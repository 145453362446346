import { defineStore } from "pinia";

export const useEvents = defineStore("events", () => {
  // State
  const eventsList = ref([]);

  return {
    eventsList,
  };
});
