import { defineStore } from "pinia";

export const useNeighborhoodDetails = defineStore("neighborhoodDetails", () => {
  // State
  let currentTab = ref("");

  return {
    currentTab,
  };
});
