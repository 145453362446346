import { defineStore } from "pinia";

export const useModalLogin = defineStore("modalLogin", () => {

  const modalIsOpen = ref(false);
  const modalTargetTab = ref("login");

  const closeModal = () => {
    modalIsOpen.value = false;
    modalTargetTab.value = "login";
  };

  const openModal = (targetTab = "login") => {
    modalTargetTab.value = typeof targetTab === "string" ? targetTab : "login";
    modalIsOpen.value = true;
  };

  return {
    closeModal,
    modalIsOpen,
    modalTargetTab,
    openModal,
  };
});
