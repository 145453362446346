import gql from "graphql-tag";

export const UPDATE_FAVORITES = gql`
mutation UpdateFavorites($uniqueId: String, $userId: ID!, $favorites: String!) {
  updateUserFavorites(input: {clientMutationId: $uniqueId, favorites: $favorites, userId: $userId}) {
    clientMutationId
    user {
      databaseId
      favorites
      firstName
      id
      jwtAuthExpiration
      jwtAuthToken
      jwtRefreshToken
    }
  }
}
`;
