import { defineStore } from "pinia";

export const useModalVideo = defineStore("modalVideo", () => {
  const modalIsOpen = ref(false);
  const videoUrl = ref("");

  const closeModal = () => {
    modalIsOpen.value = false;
  };

  const openModal = (url) => {
    videoUrl.value = url;
    modalIsOpen.value = true;
  };

  return {
    modalIsOpen,
    videoUrl,
    closeModal,
    openModal,
  };
});
