import gql from "graphql-tag";

export const LOGIN_USER = gql`
mutation LoginUser($uniqueId: String, $username: String!, $password: String!) {
  login(input: {clientMutationId: $uniqueId, username: $username, password: $password}) {
    authToken
    clientMutationId
    refreshToken
    user {
      databaseId
      favorites
      firstName
      id
      jwtAuthExpiration
    }
  }
}
`;

export const REGISTER_USER = gql`
  mutation RegisterUser($uniqueId: String, $username: String!, $password: String!, $email: String!, $firstName: String!, $lastName: String!) {
    registerUser(
      input: {
          clientMutationId: $uniqueId,
          username: $username,
          password: $password,
          email: $email,
          firstName: $firstName,
          lastName: $lastName
      }) {
      clientMutationId
      user {
        databaseId
        favorites
        firstName
        id
        jwtAuthExpiration
        jwtAuthToken
        jwtRefreshToken
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
mutation RefreshToken($jwtRefreshToken: String!, $uniqueId: String) {
  refreshJwtAuthToken(
    input: {jwtRefreshToken: $jwtRefreshToken, clientMutationId: $uniqueId}
  ) {
    authToken
    clientMutationId
  }
}
`;

export const GET_USER_DATA = gql`
query GetUserData($id: ID!) {
  user(id: $id, idType: DATABASE_ID) {
    databaseId
    favorites
    firstName
    id
    jwtAuthExpiration
    jwtAuthToken
    jwtRefreshToken
  }
}
`;
