import { defineStore } from "pinia";

export const useBreadcrumbs = defineStore("breadcrumbs", () => {
  // State
  const breadcrumbsList = ref([]);

  return {
    breadcrumbsList,
  };
});
